myApp.factory('employeeModel', ['$http', '$location', '$cookies', function($http, $location, $cookies) {

    return {
        changeRole: function(data) {
            return $http({
                header: {
                    'X-CSRF-TOKEN': csrftoken
                },
                url: baseUrl + 'api' + '/editemployee',
                method: "POST",
                data: data
            });

        },
        getEmployee: function(pageNumber) {
            return $http({
                header: {
                    'X-CSRF-TOKEN': csrftoken
                },
                url: baseUrl + 'api' + '/getemployee?page=' + pageNumber,
                method: 'GET'
            })

        },
        deleteEmployee: function(emp) {

            return $http({
                header: {
                    'X-CSRF-TOKEN': csrftoken
                },
                url: baseUrl + 'api' + '/deleteemployee',
                method: "POST",
                data: emp
            });


        },

        searchEmployee: function(searchTerm, pageNumber) {
            return $http({
                header: {
                    'X-CSRF-TOKEN': csrftoken
                },
                url: baseUrl + 'api' + '/searchemployee/' + searchTerm + '?page=' + pageNumber,
                method: 'GET'
            })

        }

    }

}]);
