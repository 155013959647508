myApp.factory('filenoModel', ['$http', function ($http) {
    return {
        insertFileno: function (filenoFormData) {
            return $http({
                header: {
                    'X-CSRF-TOKEN': csrftoken
                },
                url: baseUrl + 'api' + '/insertfileno',
                method: 'POST',
                data: filenoFormData
            })

        },
        deleteFileno: function (id) {
            return $http({
                header: {
                    'X-CSRF-TOKEN': csrftoken
                },
                url: baseUrl + 'api' + '/deletefileno',
                method: 'POST',
                data: {id: id}
            })

        },
          restoreFileno: function(id) {
            return $http({
                header: {
                    'X-CSRF-TOKEN': csrftoken
                },
                url: baseUrl + 'api' + '/restorefileno',
                method: 'POST',
                data: {
                    id: id
                }
            })

        },
        editFileno: function (fn) {
            console.log("edit:",fn)
            return $http({
                header: {
                    'X-CSRF-TOKEN': csrftoken
                },
                url: baseUrl + 'api' + '/editfileno',
                method: 'POST',
                data: fn
            })

        },
        getFileno: function( pageNumber) {
            return $http({
                header: {
                    'X-CSRF-TOKEN': csrftoken
                },
                url: baseUrl + 'api' + '/getfileno?page='+pageNumber,
                method: 'GET'
            })

        },
         getFilenoTrash: function(pageNumber) {
            return $http({
                header: {
                    'X-CSRF-TOKEN': csrftoken
                },
                url: baseUrl + 'api' + '/getfilenotrash?page=' + pageNumber,
                method: 'GET'
            })

        },

        searchFileno: function(searchTerm, pageNumber) {
            return $http({
                header: {
                    'X-CSRF-TOKEN': csrftoken
                },
                url: baseUrl + 'api' + '/searchfileno/' + searchTerm + '?page=' + pageNumber,
                method: 'GET'
            })

        },
        searchFilenoTrash: function(searchTerm, pageNumber) {
            return $http({
                header: {
                    'X-CSRF-TOKEN': csrftoken
                },
                url: baseUrl + 'api' + '/searchfilenotrash/' + searchTerm + '?page=' + pageNumber,
                method: 'GET'
            })

        }
    }

}]);
