myApp.factory('invoiceModel', ['$http', function($http) {
    return {
        insertInvoice: function(invoiceFormData) {
            return $http({
                header: {
                    'X-CSRF-TOKEN': csrftoken
                },
                url: baseUrl + 'api' + '/insertinvoice',
                method: 'POST',
                data: invoiceFormData
            })

        },
        deleteInvoice: function(id) {
            return $http({
                header: {
                    'X-CSRF-TOKEN': csrftoken
                },
                url: baseUrl + 'api' + '/deleteinvoice',
                method: 'POST',
                data: {
                    id: id
                }
            })

        },

        restoreInvoice: function(id) {
            return $http({
                header: {
                    'X-CSRF-TOKEN': csrftoken
                },
                url: baseUrl + 'api' + '/restoreinvoice',
                method: 'POST',
                data: {
                    id: id
                }
            })

        },

        editInvoice: function(cs) {
            console.log("edit:", cs)
            return $http({
                header: {
                    'X-CSRF-TOKEN': csrftoken
                },
                url: baseUrl + 'api' + '/editinvoice',
                method: 'POST',
                data: cs
            })

        },
        getInvoice: function(pageNumber) {
            return $http({
                header: {
                    'X-CSRF-TOKEN': csrftoken
                },
                url: baseUrl + 'api' + '/getinvoice?page=' + pageNumber,
                method: 'GET'
            })

        },
        getInvoiceTrash: function(pageNumber) {
            return $http({
                header: {
                    'X-CSRF-TOKEN': csrftoken
                },
                url: baseUrl + 'api' + '/getinvoicetrash?page=' + pageNumber,
                method: 'GET'
            })

        },

        searchInvoice: function(searchTerm, pageNumber) {
            return $http({
                header: {
                    'X-CSRF-TOKEN': csrftoken
                },
                url: baseUrl + 'api' + '/searchinvoice/' + searchTerm + '?page=' + pageNumber,
                method: 'GET'
            })

        },
        searchInvoiceTrash: function(searchTerm, pageNumber) {
            return $http({
                header: {
                    'X-CSRF-TOKEN': csrftoken
                },
                url: baseUrl + 'api' + '/searchinvoicetrash/' + searchTerm + '?page=' + pageNumber,
                method: 'GET'
            })

        },
        getDefaultBank: function() {
            return $http({
                header: {
                    'X-CSRF-TOKEN': csrftoken
                },
                url: baseUrl + 'api' + '/getdefaultbank',
                method: 'GET'
            })

        }

    }

}]);
