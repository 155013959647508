myApp.factory('coversheetModel', ['$http', '$filter', 'moment', function($http, $filter, moment) {
    return {
        insertCoversheet: function(coversheetFormData) {

        //     if (coversheetFormData.arrived !== undefined) {
        //         coversheetFormData.arrived = $filter('date')(coversheetFormData.arrived, 'mediumDate');

        //     }

        // console.log(coversheetFormData.arrived)

        


            return $http({
                header: {
                    'X-CSRF-TOKEN': csrftoken
                },
                url: baseUrl + 'api' + '/insertcoversheet',
                method: 'POST',
                data: coversheetFormData
            })

        },
        deleteCoversheet: function(id) {
            return $http({
                header: {
                    'X-CSRF-TOKEN': csrftoken
                },
                url: baseUrl + 'api' + '/deletecoversheet',
                method: 'POST',
                data: {
                    id: id
                }
            })

        },
        restoreCoversheet: function(id) {
            return $http({
                header: {
                    'X-CSRF-TOKEN': csrftoken
                },
                url: baseUrl + 'api' + '/restorecoversheet',
                method: 'POST',
                data: {
                    id: id
                }
            })

        },
        editCoversheet: function(cs) {
            console.log("edit:", cs)
            return $http({
                header: {
                    'X-CSRF-TOKEN': csrftoken
                },
                url: baseUrl + 'api' + '/editcoversheet',
                method: 'POST',
                data: cs
            })

        },
        getCoversheet: function(pageNumber) {
            
            return $http({
                header: {
                    'X-CSRF-TOKEN': csrftoken
                },
                url: baseUrl + 'api' + '/getcoversheet?page=' + pageNumber,
                method: 'GET'
            })

        },

        getCoversheetTrash: function(pageNumber) {
            return $http({
                header: {
                    'X-CSRF-TOKEN': csrftoken
                },
                url: baseUrl + 'api' + '/getcoversheettrash?page=' + pageNumber,
                method: 'GET'
            })

        },

        searchCoversheet: function(searchTerm, pageNumber) {
            return $http({
                header: {
                    'X-CSRF-TOKEN': csrftoken
                },
                url: baseUrl + 'api' + '/searchcoversheet/' + searchTerm + '?page=' + pageNumber,
                method: 'GET'
            })

        },
        searchCoversheetTrash: function(searchTerm, pageNumber) {
            return $http({
                header: {
                    'X-CSRF-TOKEN': csrftoken
                },
                url: baseUrl + 'api' + '/searchcoversheettrash/' + searchTerm + '?page=' + pageNumber,
                method: 'GET'
            })

        }

    }

}]);
